export default ( context, inject ) => {
  let width = 'md'
  if (window && window.innerWidth < 576) {
    width = 'xs'
  } else if (window && window.innerWidth >= 576 && window.innerWidth < 768) {
    width = 'sm'
  } else if (window && window.innerWidth >= 768 && window.innerWidth < 992) {
    width = 'md'
  } else if (window && window.innerWidth >= 992 && window.innerWidth < 1200) {
    width = 'lg'
  } else if (window && window.innerWidth >= 1200) {
    width = 'xl'
  }
  inject('deviceWidth', width)
}
