<template>
  <div class="container">
    <div class="main-container fullscreen">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-7">
            <div class="text-center">
              <h1 v-if="error.statusCode === 404" class="display-1 text-primary">4😵4</h1>
              <p class="mt-2 h3 text-dark">
                <span v-if="error.statusCode === 404">
                  {{ error.message || 'The page you were looking for was not found.' }}
                </span>
                <span v-else>
                  {{ error.message || 'An error occurred' }}
                </span>
                <br><br>
                <nuxt-link to="/">Home page</nuxt-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
  layout: 'app'
}
</script>
