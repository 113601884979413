import fetchUser from '~/apollo/queries/fetchUser'
import fetchFixtures from '~/apollo/queries/fetchFixtures'
import fetchLiveFixtures from '~/apollo/queries/fetchLiveFixtures'
import fetchFeeds from '~/apollo/queries/fetchFeeds'
import fetchUserData from '~/apollo/queries/fetchUserData'
import fetchUserNotifications from '~/apollo/queries/fetchUserNotifications'
import fetchUserFilters from '~/apollo/queries/fetchUserFilters'

export default ( app ) => {
  app.$query = {
    fetchUser: fetchUser,
    fetchFixtures: fetchFixtures,
    fetchLiveFixtures: fetchLiveFixtures,
    fetchFeeds: fetchFeeds,
    fetchUserData: fetchUserData,
    fetchUserNotifications: fetchUserNotifications,
    fetchUserFilters: fetchUserFilters
  }
}
