// need it for middleware
export default ( context, inject ) => {
  let isApp = false // always false, true only for testing
  
  context.userAgent = process.server
      ? context.req.headers['user-agent']
      : navigator.userAgent
  if (context.userAgent && context.userAgent.indexOf('gonative') > -1) {
    isApp = true
  }

  inject('isApp', isApp)
  inject('isInReview', process.env.APP_IN_REVIEW === 'true') // always false, true only when the app is sent for review to hide specific sections
}
