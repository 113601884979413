export default async function ({ route, app, redirect, store }) {
  if (!store.state.user.username) {
    if (!app.context.$isApp) {
      localStorage.redirect_url = route.path
    }
    return redirect('/login')
  } else {
    await store.dispatch('GET_USER_DETAILS')
    return true
  }
}
