const middleware = {}

middleware['anonymous'] = require('../middleware/anonymous.js')
middleware['anonymous'] = middleware['anonymous'].default || middleware['anonymous']

middleware['appTitles'] = require('../middleware/appTitles.js')
middleware['appTitles'] = middleware['appTitles'].default || middleware['appTitles']

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['userDetails'] = require('../middleware/userDetails.js')
middleware['userDetails'] = middleware['userDetails'].default || middleware['userDetails']

export default middleware
