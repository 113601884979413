import * as moment from 'moment'
import api from '~/utils/api'
import Auth from '@aws-amplify/auth'

export const state = () => ({
  user: {
    username: null
  },
  user_details: {
    feed: {},
    feed_keys: [
      "ppg",
      "o15",
      "o25",
      "btts",
      "avg_goals",
      "avg_goals_scored",
      "avg_goals_concedeed",
      "clean_sheet",
      "failed_to_score",
      "o35"
    ],
    user_data: {
      user_plan: [],
      timezone: 1,
      expire_at: null,
      ref: null,
      earned_pro: {},
      discord_user: null,
      expire_days: 0
    },
    user_feeds: [],
    user_leagues: {
      "2": true,
      "5": true,
      "8": true,
      "9": true,
      "12": true,
      "14": true,
      "72": true,
      "74": true,
      "82": true,
      "85": true,
      "88": true,
      "286": true,
      "301": true,
      "304": true,
      "307": true,
      "325": true,
      "334": true,
      "345": true,
      "360": true,
      "372": true,
      "384": true,
      "387": true,
      "390": true,
      "399": true,
      "405": true,
      "444": true,
      "447": true,
      "453": true,
      "456": true,
      "462": true,
      "465": true,
      "474": true,
      "486": true,
      "501": true,
      "564": true,
      "172": true,
      "175": true,
      "178": true,
      "181": true,
      "184": true,
      "187": true,
      "190": true,
      "199": true,
      "208": true,
      "211": true,
      "214": true,
      "217": true,
      "220": true,
      "229": true,
      "244": true,
      "247": true,
      "253": true,
      "262": true,
      "271": true,
      "274": true,
      "531": true,
      "540": true,
      "567": true,
      "570": true,
      "573": true,
      "591": true,
      "594": true,
      "600": true,
      "603": true,
      "606": true,
      "609": true,
      "624": true,
      "636": true,
      "648": true,
      "651": true,
      "654": true,
      "663": true,
      "672": true,
      "678": true,
      "681": true,
      "687": true,
      "693": true,
      "696": true,
      "711": true,
      "714": true,
      "717": true,
      "720": true,
      "723": true,
      "726": true,
      "729": true,
      "732": true,
      "746": true,
      "755": true,
      "764": true,
      "770": true,
      "779": true,
      "800": true,
      "1125": true,
      "720": true,
      "1325": true,
      "1326": true,
      "1538": true,
      "2286": true
    },
    user_games: {},
    user_filters: [],
    color_limit: {
      green_limit: 75,
      green_limit_reverse: 25,
      red_limit: 25,
      red_limit_reverse: 75
    },
    user_notifications: {
      "goals":{
        "name":"Goals",
        "order":1,
        "enabled":true,
        "key": "goals"
      },
      "corners":{
        "name":"Corners",
        "order":2,
        "enabled":true,
        "key": "corners"
      },
      "cards":{
        "name":"Cards",
        "order":3,
        "enabled":true,
        "key": "cards"
      },
      "substitutions":{
        "name":"Substitutions",
        "order":4,
        "enabled":true,
        "key": "substitutions"
      },
      "var":{
        "name":"VAR",
        "order":5,
        "enabled":true,
        "key": "var"
      },
      "game_start":{
        "name":"Game started",
        "order":6,
        "enabled":true,
        "key": "game_start"
      },
      "game_ht":{
        "name":"Game is HT",
        "order":7,
        "enabled":true,
        "key": "game_ht"
      },
      "game_end":{
        "name":"Game ended",
        "order":8,
        "enabled":true,
        "key": "game_end"
      },
      "penalty":{
        "name":"Penalty",
        "order":9,
        "enabled":true,
        "key": "penalty"
      },
      "lineups":{
        "name":"Lineups available",
        "order":10,
        "enabled":true,
        "key": "lineups"
      },
      "inplay_alerts":{
        "name":"Inplay alerts",
        "order":11,
        "enabled":true,
        "key": "inplay_alerts"
      }
    },
    user_alerts: [],
    user_ref: []
  },
  stats: [
    {
      "name": "PPG",
      "desc": "Points per game",
      "key": "ppg",
      "key_v2": "ppg",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "PG",
      "desc": "Played games",
      "key": "i",
      "key_v2": "results",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Win",
      "desc": "Win",
      "key": "w",
      "key_v2": "w",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Draw",
      "desc": "Draw",
      "key": "d",
      "key_v2": "d",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Lost",
      "desc": "Lost",
      "key": "l",
      "key_v2": "l",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Win HT",
      "desc": "Win Half Time",
      "key": "ht_w",
      "key_v2": "ht_w",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Draw HT",
      "desc": "Draw Half Time",
      "key": "ht_d",
      "key_v2": "ht_d",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Lost HT",
      "desc": "Lost Half Time",
      "key": "ht_l",
      "key_v2": "ht_l",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Win SH",
      "desc": "Win Second Half",
      "key": "sh_w",
      "key_v2": "sh_w",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Draw SH",
      "desc": "Draw Second Half",
      "key": "sh_d",
      "key_v2": "sh_d",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Lost SH",
      "desc": "Lost Second Half",
      "key": "sh_l",
      "key_v2": "sh_l",
      "category": "General",
      "__typename": "stats"
    },
    {
      "name": "Avg. Gls",
      "desc": "Average game goals",
      "key": "avg_goals",
      "key_v2": "avg_goals",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "Avg. GS",
      "desc": "Average goals scored",
      "key": "avg_goals_scored",
      "key_v2": "avg_goals_scored",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "Avg. GC",
      "desc": "Average goals conceded",
      "key": "avg_goals_concedeed",
      "key_v2": "avg_goals_conceded",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "xG",
      "desc": "Expected goals (xG)",
      "key": "xg",
      "key_v2": "avg_xg",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "xGA",
      "desc": "Expected goals against (xGA)",
      "key": "xg_against",
      "key_v2": "avg_xg_against",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "CS",
      "desc": "Clean sheet",
      "key": "clean_sheet",
      "key_v2": "clean_sheet",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "FTS",
      "desc": "Failed to score",
      "key": "failed_to_score",
      "key_v2": "failed_to_score",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "O1.5 TG",
      "desc": "Over 1.5 team goals",
      "key": "o15_team",
      "key_v2": "o15_team",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "O1.5 TGA",
      "desc": "Over 1.5 team goals against",
      "key": "o15_team_against",
      "key_v2": "o15_team_against",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "BTTS",
      "desc": "Both teams to score",
      "key": "btts",
      "key_v2": "btts",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "BTTS & O2.5",
      "desc": "Both teams to score & over 2.5 game goals",
      "key": "btts_o25",
      "key_v2": "btts_o25",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "O0.5 Gls",
      "desc": "Over 0.5 game goals",
      "key": "o05",
      "key_v2": "o05",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "O1.5 Gls",
      "desc": "Over 1.5 game goals",
      "key": "o15",
      "key_v2": "o15",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "O2.5 Gls",
      "desc": "Over 2.5 game goals",
      "key": "o25",
      "key_v2": "o25",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "O3.5 Gls",
      "desc": "Over 3.5 game goals",
      "key": "o35",
      "key_v2": "o35",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "O4.5 Gls",
      "desc": "Over 4.5 game goals",
      "key": "o45",
      "key_v2": "o45",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "30-HT",
      "desc": "Goals 30\'-HT",
      "key": "goals_30_ht",
      "key_v2": "goals_30_ht",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "75-FT",
      "desc": "Goals 75\'-FT",
      "key": "goals_75_ft",
      "key_v2": "goals_75_ft",
      "category": "Goals",
      "__typename": "stats"
    },
    {
      "name": "Avg. CK",
      "desc": "Average game corners",
      "key": "avg_corners",
      "key_v2": "avg_corners",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O7.5 CK",
      "desc": "Over 7.5 game corners",
      "key": "o75_corners",
      "key_v2": "o75_corners",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O8.5 CK",
      "desc": "Over 8.5 game corners",
      "key": "o85_corners",
      "key_v2": "o85_corners",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O9.5 CK",
      "desc": "Over 9.5 game corners",
      "key": "o95_corners",
      "key_v2": "o95_corners",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O10.5 CK",
      "desc": "Over 10.5 game corners",
      "key": "o105_corners",
      "key_v2": "o105_corners",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "Avg. CK FH",
      "desc": "Average game corners first half",
      "key": "avg_corners_fh",
      "key_v2": "avg_corners_fh",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O2.5 CK FH",
      "desc": "Over 2.5 game corners first half",
      "key": "o25_corners_ht",
      "key_v2": "o25_corners_ht",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O3.5 CK FH",
      "desc": "Over 3.5 game corners first half",
      "key": "o35_corners_ht",
      "key_v2": "o35_corners_ht",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O4.5 CK FH",
      "desc": "Over 4.5 game corners first half",
      "key": "o45_corners_ht",
      "key_v2": "o45_corners_ht",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "Avg. CK SH",
      "desc": "Average game corners second half",
      "key": "avg_corners_sh",
      "key_v2": "avg_corners_sh",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O2.5 CK SH",
      "desc": "Over 2.5 game corners second half",
      "key": "o25_corners_sh",
      "key_v2": "o25_corners_sh",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O3.5 CK SH",
      "desc": "Over 3.5 game corners second half",
      "key": "o35_corners_sh",
      "key_v2": "o35_corners_sh",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "O4.5 CK SH",
      "desc": "Over 4.5 game corners second half",
      "key": "o45_corners_sh",
      "key_v2": "o45_corners_sh",
      "category": "Game Corners",
      "__typename": "stats"
    },
    {
      "name": "Avg. TF CK",
      "desc": "Average corners for team",
      "key": "avg_team_corners_for",
      "key_v2": "avg_team_corners_for",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "Avg. TA CK",
      "desc": "Average corners against team",
      "key": "avg_team_corners_against",
      "key_v2": "avg_team_corners_against",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O3.5 TCK",
      "desc": "Over 3.5 team corners",
      "key": "o35_corners_team",
      "key_v2": "o35_corners_team",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O4.5 TCK",
      "desc": "Over 4.5 team corners",
      "key": "o45_corners_team",
      "key_v2": "o45_corners_team",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O5.5 TCK",
      "desc": "Over 5.5 team corners",
      "key": "o55_corners_team",
      "key_v2": "o55_corners_team",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O0.5 TCK FH",
      "desc": "Over 0.5 team corners first half",
      "key": "o05_corners_team_ht",
      "key_v2": "o05_corners_team_ht",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O1.5 TCK FH",
      "desc": "Over 1.5 team corners first half",
      "key": "o15_corners_team_ht",
      "key_v2": "o15_corners_team_ht",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O2.5 TCK FH",
      "desc": "Over 2.5 team corners first half",
      "key": "o25_corners_team_ht",
      "key_v2": "o25_corners_team_ht",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O3.5 TCK FH",
      "desc": "Over 3.5 team corners first half",
      "key": "o35_corners_team_ht",
      "key_v2": "o35_corners_team_ht",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O0.5 TCK SH",
      "desc": "Over 0.5 team corners second half",
      "key": "o05_corners_team_sh",
      "key_v2": "o05_corners_team_sh",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O1.5 TCK SH",
      "desc": "Over 1.5 team corners second half",
      "key": "o15_corners_team_sh",
      "key_v2": "o15_corners_team_sh",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O2.5 TCK SH",
      "desc": "Over 2.5 team corners second half",
      "key": "o25_corners_team_sh",
      "key_v2": "o25_corners_team_sh",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "O3.5 TCK SH",
      "desc": "Over 3.5 team corners second half",
      "key": "o35_corners_team_sh",
      "key_v2": "o35_corners_team_sh",
      "category": "Team Corners",
      "__typename": "stats"
    },
    {
      "name": "0-10 CK",
      "desc": "Corners 0\'-10\'",
      "key": "corners_0_10",
      "key_v2": "corners_0_10",
      "category": "Corners",
      "__typename": "stats"
    },
    {
      "name": "35-HT CK",
      "desc": "Corners 35\'-HT",
      "key": "corners_35_ht",
      "key_v2": "corners_35_ht",
      "category": "Corners",
      "__typename": "stats"
    },
    {
      "name": "80-FT CK",
      "desc": "Corners 80\'-FT\'",
      "key": "corners_80_ft",
      "key_v2": "corners_80_ft",
      "category": "Corners",
      "__typename": "stats"
    },
    {
      "name": "Avg. YC",
      "desc": "Average game yellow cards",
      "key": "avg_cards",
      "key_v2": "avg_cards",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O1.5 YC",
      "desc": "Over 1.5 game yellow cards",
      "key": "o15_cards",
      "key_v2": "o15_cards",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O2.5 YC",
      "desc": "Over 2.5 game yellow cards",
      "key": "o25_cards",
      "key_v2": "o25_cards",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O3.5 YC",
      "desc": "Over 3.5 game yellow cards",
      "key": "o35_cards",
      "key_v2": "o35_cards",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O4.5 YC",
      "desc": "Over 4.5 game yellow cards",
      "key": "o45_cards",
      "key_v2": "o45_cards",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "Avg. YC FH",
      "desc": "Average game yellow cards first half",
      "key": "avg_cards_fh",
      "key_v2": "avg_cards_fh",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O0.5 YC FH",
      "desc": "Over 0.5 game yellow cards first half",
      "key": "o05_cards_ht",
      "key_v2": "o05_cards_ht",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O1.5 YC FH",
      "desc": "Over 1.5 game yellow cards first half",
      "key": "o15_cards_ht",
      "key_v2": "o15_cards_ht",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O2.5 YC FH",
      "desc": "Over 2.5 game yellow cards first half",
      "key": "o25_cards_ht",
      "key_v2": "o25_cards_ht",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "Avg. YC SH",
      "desc": "Average game yellow cards second half",
      "key": "avg_cards_sh",
      "key_v2": "avg_cards_sh",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O0.5 YC SH",
      "desc": "Over 0.5 game yellow cards second half",
      "key": "o05_cards_sh",
      "key_v2": "o05_cards_sh",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O1.5 YC SH",
      "desc": "Over 1.5 game yellow cards second half",
      "key": "o15_cards_sh",
      "key_v2": "o15_cards_sh",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "O2.5 YC SH",
      "desc": "Over 2.5 game yellow cards second half",
      "key": "o25_cards_sh",
      "key_v2": "o25_cards_sh",
      "category": "Game Cards",
      "__typename": "stats"
    },
    {
      "name": "Avg. TF YC",
      "desc": "Average yellow cards for team",
      "key": "avg_team_cards_for",
      "key_v2": "avg_team_cards_for",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "Avg. TA YC",
      "desc": "Average yellow cards against team",
      "key": "avg_team_cards_against",
      "key_v2": "avg_team_cards_against",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "O0.5 TYC",
      "desc": "Over 0.5 team yellow cards",
      "key": "o05_cards_team",
      "key_v2": "o05_cards_team",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "O1.5 TYC",
      "desc": "Over 1.5 team yellow cards",
      "key": "o15_cards_team",
      "key_v2": "o15_cards_team",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "O2.5 TYC",
      "desc": "Over 2.5 team yellow cards",
      "key": "o25_cards_team",
      "key_v2": "o25_cards_team",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "O0.5 TYC FH",
      "desc": "Over 0.5 team yellow cards first half",
      "key": "o05_cards_team_ht",
      "key_v2": "o05_cards_team_ht",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "O1.5 TYC FH",
      "desc": "Over 1.5 team yellow cards first half",
      "key": "o15_cards_team_ht",
      "key_v2": "o15_cards_team_ht",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "O0.5 TYC SH",
      "desc": "Over 0.5 team yellow cards second half",
      "key": "o05_cards_team_sh",
      "key_v2": "o05_cards_team_sh",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "O1.5 TYC SH",
      "desc": "Over 1.5 team yellow cards second half",
      "key": "o15_cards_team_sh",
      "key_v2": "o15_cards_team_sh",
      "category": "Team Cards",
      "__typename": "stats"
    },
    {
      "name": "Avg. Gls FH",
      "desc": "Average game goals first half",
      "key": "avg_goals_fh",
      "key_v2": "avg_goals_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "Avg. GS FH",
      "desc": "Average goals scored first half",
      "key": "avg_goals_scored_fh",
      "key_v2": "avg_goals_scored_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "Avg. GC FH",
      "desc": "Average goals conceded first half",
      "key": "avg_goals_concedeed_fh",
      "key_v2": "avg_goals_conceded_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "CS FH",
      "desc": "Clean sheet first half",
      "key": "clean_sheet_fh",
      "key_v2": "clean_sheet_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "FTS FH",
      "desc": "Failed to score first half",
      "key": "failed_to_score_fh",
      "key_v2": "failed_to_score_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "BTTS FH",
      "desc": "Both teams to score first half",
      "key": "btts_fh",
      "key_v2": "btts_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "O0.5 Gls FH",
      "desc": "Over 0.5 game goals first half",
      "key": "o05_fh",
      "key_v2": "o05_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "O1.5 Gls FH",
      "desc": "Over 1.5 game goals first half",
      "key": "o15_fh",
      "key_v2": "o15_fh",
      "category": "First Half",
      "__typename": "stats"
    },
    {
      "name": "Avg. Gls SH",
      "desc": "Average game goals second half",
      "key": "avg_goals_sh",
      "key_v2": "avg_goals_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name": "Avg. GS SH",
      "desc": "Average goals scored second half",
      "key": "avg_goals_scored_sh",
      "key_v2": "avg_goals_scored_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name": "Avg. GC SH",
      "desc": "Average goals conceded second half",
      "key": "avg_goals_concedeed_sh",
      "key_v2": "avg_goals_conceded_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name": "CS SH",
      "desc": "Clean sheet second half",
      "key": "clean_sheet_sh",
      "key_v2": "clean_sheet_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name": "FTS SH",
      "desc": "Failed to score second half",
      "key": "failed_to_score_sh",
      "key_v2": "failed_to_score_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name": "BTTS SH",
      "desc": "Both teams to score second half",
      "key": "btts_sh",
      "key_v2": "btts_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name": "O0.5 Gls SH",
      "desc": "Over 0.5 game goals second half",
      "key": "o05_sh",
      "key_v2": "o05_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name": "O1.5 Gls SH",
      "desc": "Over 1.5 game goals second half",
      "key": "o15_sh",
      "key_v2": "o15_sh",
      "category": "Second Half",
      "__typename": "stats"
    },
    {
      "name":"Poss",
      "desc":"Possession",
      "key":"possession",
      "key_v2":"avg_possession",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"Att.",
      "desc":"Attacks",
      "key":"attacks",
      "key_v2":"avg_attacks",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"Dang. Att.",
      "desc":"Dangerous Attacks",
      "key":"dangerous_attacks",
      "key_v2":"avg_dangerous_attacks",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"SOT",
      "desc":"Shots on target",
      "key":"shots_on_target",
      "key_v2":"avg_shots_on_target",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"SOFFT",
      "desc":"Shots off target",
      "key":"shots_off_target",
      "key_v2":"avg_shots_off_target",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"Shots",
      "desc":"Shots",
      "key":"shots",
      "key_v2":"avg_shots",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"SFG",
      "desc":"Shots for goal",
      "key":"shots_for_goal",
      "key_v2":"avg_shots_for_goal",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"SIB",
      "desc":"Shots inside box",
      "key":"shots_insidebox",
      "key_v2":"avg_shots_insidebox",
      "category":"Extra Stats",
      "__typename":"stats"
    },
    {
      "name":"SOB",
      "desc":"Shots outside box",
      "key":"shots_outsidebox",
      "key_v2":"avg_shots_outsidebox",
      "category":"Extra Stats",
      "__typename":"stats"
    }
  ]
})

export const mutations = {
  INITIATE_USER(state) {
    state.user = {
      username: null
    },
    state.user_details = {
      feed: {},
      feed_keys: [
        "o15",
        "o25",
        "btts",
        "avg_goals",
        "avg_goals_scored",
        "avg_goals_concedeed",
        "clean_sheet",
        "failed_to_score",
        "o05",
        "o35"
      ],
      user_data: {
        user_plan: [],
        timezone: 1,
        expire_at: null,
        ref: null,
        earned_pro: {},
        discord_user: null,
        extra_days: 0
      },
      user_feeds: [],
      user_leagues: {
        "2": true,
        "5": true,
        "8": true,
        "82": true,
        "301": true,
        "384": true,
        "564": true
      },
      user_games: {},
      user_filters: [],
      color_limit: {
        green_limit: 75,
        green_limit_reverse: 25,
        red_limit: 25,
        red_limit_reverse: 75
      },
      user_notifications: {
        "cards":{
           "name":"Cards",
           "order":3,
           "enabled":true,
           "key": "cards"
        },
        "goals":{
           "name":"Goals",
           "order":1,
           "enabled":true,
           "key": "goals"
        },
        "corners":{
           "name":"Corners",
           "order":2,
           "enabled":true,
           "key": "corners"
        },
        "game_ht":{
           "name":"Game is HT",
           "order":6,
           "enabled":true,
           "key": "game_ht"
        },
        "lineups":{
           "name":"Lineups available",
           "order":9,
           "enabled":true,
           "key": "lineups"
        },
        "penalty":{
           "name":"Penalty",
           "order":8,
           "enabled":true,
           "key": "penalty"
        },
        "game_end":{
           "name":"Game ended",
           "order":7,
           "enabled":true,
           "key": "game_end"
        },
        "game_start":{
           "name":"Game started",
           "order":5,
           "enabled":true,
           "key": "game_start"
        },
        "inplay_alerts":{
           "name":"Inplay alerts",
           "order":10,
           "enabled":true,
           "key": "inplay_alerts"
        },
        "substitutions":{
           "name":"Substitutions",
           "order":4,
           "enabled":true,
           "key": "substitutions"
        }
     },
     user_alerts: [],
     user_ref: []
    }
  },
  // authenticated user
  SET_USER(state, payload) {
    state.user = payload
  },
  // user details
  SET_USER_DETAILS(state, payload) {
    Object.keys(payload).forEach(key => {
      state.user_details[key] = payload[key]
    })
  },
  SET_TIMEZONE(state, payload) {
    state.user_details.user_data.timezone = payload
  },
  // all stats of the app
  SET_STATS(state, payload) {
    state.stats = payload
  },
  CLEAR_STATS(state) {
    state.stats = {}
  }
}

export const actions = {
  async GET_USER ({ state, commit }) {
    if (!state.user.username) {
      return new Promise(async (resolve) => {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            commit('SET_USER', user)
            resolve(true)
          })
          .catch(() => {
            commit('SET_USER', {username: null})
            resolve(true)
          })
        })
    }
  },

  async GET_USER_DETAILS ({state, commit, dispatch}) {
    try {
      await dispatch('GET_USER')
      // if logged user
      if (state.user.username) {
        // set user to sentry
        this.$sentry.setUser({ username: state.user.username })
        // check if user has multiple accounts
        if (localStorage.getItem('checkUser')) {
          await api.checkUser(state.user.username)
          localStorage.removeItem('checkUser')
        }
        const client = this.app.apolloProvider.defaultClient
        return await client.query({
          query: this.app.context.$query.fetchUser,
          variables: { date: moment.utc().format("YYYY-MM-DD") },
          fetchPolicy: 'no-cache'
        })
        .then(({ data }) => {
          // if no user is found then show error message
          if (data.users.length) {
            var feed_keys = data.user_feed.find(feed => feed.is_default == true) || data.user_feed[0]
            var user_leagues = data.user_leagues[0].leagues
            var user_games = data.user_games[0] ? data.user_games[0].games : {}
            var user_alerts = data.user_alerts.length ? data.user_alerts : []
            var user_ref = data.user_ref.length ? data.user_ref : []
            var user_feeds = data.user_feed.length ? data.user_feed : []
            const obj = {
              user_data: data.users[0], 
              feed_keys: data.users[0].expire_at ? Object.values(feed_keys.feed) : state.user_details.feed_keys,
              color_limit: data.user_feed[0].color_limit,
              user_leagues,
              user_games,
              user_alerts,
              user_ref,
              user_feeds
            }
            commit('SET_USER_DETAILS', obj)
          } else {
            return window.location.href = '/logout'
          }
        })
        .catch(e => {
          this.$sentry.captureException(e, {
            tags: {
              component: 'index-store',
              method: 'GET_USER_DETAILS'
            },
          })
          return window.location.href = '/logout'
        })
      }
    } catch (e) {
      commit('SET_USER', {username: null})
    }
  },
  async GET_USER_DATA ({ state, commit }, payload) {
    // logged user
    if (payload.username) {
      const client = this.app.apolloProvider.defaultClient
      return await client.query({
        query: this.app.context.$query.fetchUserData,
        fetchPolicy: 'no-cache'
      })
      .then(({ data }) => {
        // if no user is found then show error message
        if (data.users.length) {
          try{
            let user_data = JSON.parse(JSON.stringify(state.user_details.user_data))
            commit('SET_USER_DETAILS', { user_data })
          } catch (e) {
            console.log(e)
          }
        } else {
          return window.location.href = '/logout'
        }
      })
      .catch(e => {
        this.$sentry.captureException(e, {
          tags: {
            component: 'index-store',
            method: 'GET_USER_DATA'
          },
        })
        return window.location.href = '/logout'
      })
    }
  },
  async GET_USER_NOTIFICATIONS ({state, commit, dispatch}) {
    try {
      await dispatch('GET_USER')
      if (state.user.username) {
        const client = this.app.apolloProvider.defaultClient
        const userDevices = await client.query({
          query: this.app.context.$query.fetchUserNotifications,
          variables: { user: state.user.username },
          fetchPolicy: 'no-cache'
        })
        let user_notifications = userDevices.data.user_devices.length ? JSON.parse(JSON.stringify(userDevices.data.user_devices[0].notifications_type)) : JSON.parse(JSON.stringify(state.user_details.user_notifications))
        // set key for user_notifications
        Object.keys(user_notifications).forEach(key => user_notifications[key].key = key)
        commit('SET_USER_DETAILS', { user_notifications })
      }
    } catch (e) {
      commit('SET_USER', {username: null})
      this.$sentry.captureException(e, {
        tags: {
          component: 'index-store',
          method: 'GET_USER_NOTIFICATIONS'
        },
      })
      return window.location.href = '/logout'
    }
  },
  async GET_USER_FILTERS ({state, commit, dispatch}) {
    try {
      await dispatch('GET_USER')
      if (state.user.username) {
        const client = this.app.apolloProvider.defaultClient
        const userFilters = await client.query({
          query: this.app.context.$query.fetchUserFilters,
          variables: { user: state.user.username },
          fetchPolicy: 'no-cache'
        })
        const user_filters = userFilters.data.user_filters.length ? userFilters.data.user_filters : []
        commit('SET_USER_DETAILS', { user_filters })
      }
    } catch (e) {
      commit('SET_USER', {username: null})
      this.$sentry.captureException(e, {
        tags: {
          component: 'index-store',
          method: 'GET_USER_FILTERS'
        },
      })
      return window.location.href = '/logout'
    }
  }
}
