export default ({ }, inject) => {
  const isTestEnabled = (testName) => {
    if (process.client) {
      const cookies = document.cookie.split(';')
      for (const cookie of cookies) {
        if (cookie.includes(testName)) {
          return true
        }
      }
    }
    return false
  }

  inject('abtest', isTestEnabled)
}
