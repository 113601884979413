import Auth from '@aws-amplify/auth'

export default async function ({ store, redirect }) {
  // clear websocket
  return await Auth.currentAuthenticatedUser()
    .then(() => {
      return redirect('/')
    })
    .catch((e) => {
      return true
    })
}
