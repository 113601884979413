export const state = () => ({
  id: null,
  name: null,
  updated: 0,
  filters: {},
  queryFilters: {},
  lastGames: 'l5',
  type: 'home-away'
})

export const mutations = {
  INITIATE_FILTERS(state) {
    state.updated = 0,
    state.filters = {}
    state.queryFilters = {}
    state.lastGames = 'l5',
    state.type = 'home-away'
  },
  SET_ID(state, payload) {
    state.id = payload
  },
  SET_NAME(state, payload) {
    state.name = payload
  },
  SET_FILTERS(state, payload) {
    if(payload) {
      state.updated = Date.now()
    } else {  
      state.updated = 0
    }
    state.filters = payload
    // build filters
    if(Object.keys(payload)) {
      var filters = {}
      for(let key of Object.keys(payload)) {
        payload[key].is_used = false
        if (key == 'is_ft') {
          if (payload[key].value) {
            filters[key] = true
            payload[key].is_used = true
          } else {
            payload[key].is_used = false
          }
        }
        // run checks
        if(payload[key].min || payload[key].max) {
          payload[key].is_used = true
          if(payload[key].min && (parseFloat(payload[key].min) < payload[key].expectedMin || parseFloat(payload[key].min) > payload[key].expectedMax))
            payload[key].min = payload[key].expectedMax
          if(payload[key].max && (parseFloat(payload[key].max) < payload[key].expectedMin || parseFloat(payload[key].max) > payload[key].expectedMax))
            payload[key].max = payload[key].expectedMax
          if(parseFloat(payload[key].min) > parseFloat(payload[key].max) && payload[key].min && payload[key].max)
            payload[key].max = payload[key].min
        } else {
          if (payload[key].value || payload[key].length) {
            payload[key].is_used = true
          }
        }
        // end checks
        // build filters object
        if(payload[key].is_used) {
          filters[key] = {min: null, max: null}
          if(payload[key].min) {
            filters[key].min = payload[key].min
          }
          if(payload[key].max) {
            filters[key].max = payload[key].max
          }
          filters[key].key = payload[key].key
          if (payload[key].value) {
            filters[key].value = payload[key].value
          }
          if (payload[key].length) {
            filters[key] = payload[key]
          }
          if (key.includes('odds') || key.includes('inplayOdds')) {
            filters[key].label = payload[key].label
            filters[key].bookmakerId = payload[key].bookmakerId
            filters[key].marketId = payload[key].marketId
            filters[key].tab = payload[key].tab
          }
        }
      }
      state.queryFilters = filters
    }
  },
  SET_LAST_GAMES(state, payload) {
    state.lastGames = payload
  },
  SET_FILTER_TYPE(state, payload) {
    state.type = payload
  }
}
