import Auth from '@aws-amplify/auth'
import jwtDecode from 'jwt-decode'
import Cookie from 'cookie-universal'
const cookies = Cookie()

export default {
  async refreshToken () {
    return new Promise(async (resolve, reject) => {
      if(localStorage.idToken) {
        var exp = jwtDecode(localStorage.idToken).exp
        // expDate in minutes
        var expDate = parseInt((exp*1000 - Date.now())/1000/60)
        // console.log('exp', expDate)
        if(expDate < 10) {
          Auth.currentSession()
            .then((data) => {
              const {idToken} = data
              localStorage.idToken = idToken.jwtToken
              cookies.set('apollo-token', idToken.jwtToken)
              resolve(true)
            })
            .catch((err) => {
              localStorage.redirect_url = window.location.pathname
              cookies.removeAll()
              localStorage.removeItem('idToken')
              reject(err)
            })
        } else {
          resolve(true)
        }
      } else {
        resolve(true)
      }
    })
  },
  
  async getToken () {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const currentSession = cognitoUser.signInUserSession
      let token = currentSession.idToken.getJwtToken()
      return token || ''
    } catch (e) {
      console.error("error getting jwt", e)
      return window.location.href = '/logout'
    }
  }
}
