import * as moment from 'moment'
import api from '~/utils/api'
import axios from 'axios'
import sidebarStats from '~/utils/sidebar_stats.json'

export const state = () => ({
  loader: false,
  loader_sidebar: false,
  live: false,
  showDetailsMobile: false,
  fixtures: {
    date: null,
    data: [],
    page: 1,
    meta: 0,
    favLeagues: true
  },
  sidebar_fixture: null,
  filtered_fixtures: null,
  filter_keys: null,
  source: null,
  source_sidebar: null,
  showPressureIndexToggle: false, // used on live page to switch between stats view and pressure index view
  fixturesParams: {}, // used to keep in state params used for getFixtures
  states: {
    ns: ['NS'],
    live: ['INPLAY_1ST_HALF', 'INPLAY_2ND_HALF', 'INPLAY_ET', 'INPLAY_ET_2ND_HALF', 'INPLAY_PENALTIES', 'HT', 'BREAK', 'PEN_BREAK'],
    ft: ['FT', 'AET', 'FT_PEN'],
    other: ['CANCELLED', 'DELAYED', 'AWARDED', 'WO', 'ABANDONED', 'INTERRUPTED', 'AWAITING_UPDATES']
  }
})

export const mutations = {
  SET_LOADER(state, loader) {
    state.loader = loader
  },
  SET_LOADER_SIDEBAR(state, loader) {
    state.loader_sidebar = loader
  },
  SET_LIVE(state, payload) {
    state.live = payload
  },
  SET_META(state, payload) {
    state.fixtures.meta = payload
  },
  SET_FAV_LEAGUES(state, payload) {
    state.fixtures.favLeagues = payload
  },
  SET_FIXTURES(state, fixtures) {
    if(fixtures.data.length) {
      state.fixtures.date = fixtures.date
      state.fixtures.data.push(...fixtures.data)
    } else if (!fixtures.data.length && fixtures.page == 1) {
      state.fixtures.date = fixtures.date
      state.fixtures.data = []
    }
  },
  UPDATE_FIXTURES_V2 (state, payload) {
    if (payload) {
      // find and update all live fixtures - the ones that are in the page
      // we can't add games that are not in the page because that would mean to know the filters, page, etc - too complicated for now with no extra value
      let fixture = state.fixtures.data.find(fixture => fixture.id === payload.id)
      if (state.sidebar_fixture?.id === payload.id) {
        payload.state ? state.sidebar_fixture.state = payload.state : null
        payload.scores ? state.sidebar_fixture.scores = payload.scores : null
        payload.game_stats ? state.sidebar_fixture.game_stats = payload.game_stats : null
        payload.minute ? state.sidebar_fixture.minute = payload.minute : null
      }
      if (fixture) {
        payload.state ? fixture.state = payload.state : null
        payload.scores ? fixture.scores = payload.scores : null
        payload.game_stats ? fixture.game_stats = payload.game_stats : null
        payload.minute ? fixture.minute = payload.minute : null
      }
    }
  },
  CLEAR_FIXTURES(state) {
    state.fixtures = {
      date: null,
      data: [],
      page: 1,
      meta: 0,
      favLeagues: true
    }
  },
  SET_SIDEBAR_FIXTURE(state, fixture) {
    state.sidebar_fixture = fixture
  },
  SET_SIDEBAR_FIXTURE_PROP(state, payload) {
    state.sidebar_fixture[payload.prop] = payload.value
  },
  SET_SOURCE (state, payload) {
    state.source = payload
  },
  SET_SOURCE_SIDEBAR (state, payload) {
    state.source_sidebar = payload
  },
  SET_PRESSURE_INDEX_TOGGLE (state, payload) {
    state.showPressureIndexToggle = payload
  },
  SET_FIXTURES_PARAMS (state, payload) {
    state.fixturesParams = payload
  }
}

export const actions = {
  async GET_FIXTURES_V2({commit, state, rootState, dispatch}, payload) {
    commit('SET_LOADER', true)
    // clear prev connections
    if (state.source) {
      state.source.cancel ()
    }
    commit('SET_SOURCE', axios.CancelToken.source())
    // clear sidebar
    if (payload.page === 1 && state.fixtures.favLeagues) {
      commit('SET_SIDEBAR_FIXTURE', null)
    }
    let filters = {}
    // live filter
    const live = payload?.query?.live === 'true' ? true : false
    commit('SET_LIVE', live)
    if (!live) {
      commit('SET_PRESSURE_INDEX_TOGGLE', false)
    }
    // data is sent with timezone added, timezone is subtracted server side and added after utc search
    const timezone = localStorage.getItem('timezone') || rootState.user_details.user_data.timezone
    let date = moment().utcOffset(timezone).format('YYYY-MM-DD')
    if (payload?.query?.date && moment(payload.query.date, "YYYY-MM-DD", true).isValid()) {
      date = moment(payload.query.date).format('YYYY-MM-DD')
    }
    const last_games = localStorage.getItem('lastGames') || rootState.filters.lastGames
    const filter_type = localStorage.getItem('filterType') || rootState.filters.type
    const page = payload.page
    const limit = 30
    // do not add filter for my games page
    if (payload.query.mygames == 'true' && rootState.user_details.user_data.expire_at) {
      if (Object.keys(rootState.user_details.user_games).length) {
        filters.is_ft = {key: 'time.status', min: null, max: null, value: true}
        filters.fixtures = Object.keys(rootState.user_details.user_games).map(Number)
      } else {
        filters.fixtures = [1] // workaround for mygames page, todo: improve it
      }
    } else {
      filters = {...rootState.filters.queryFilters}
      if (payload.query.myfilters == 'true') {
        filters.userId = rootState.user.username
      }
    }
    // add users's league as a filter
    filters.withFavLeagues = state.fixtures.favLeagues // flag to know if we filter fav leagues first or not
    const favLeagues = Object.keys(rootState.user_details.user_leagues).filter(key => rootState.user_details.user_leagues[key] === true).map(Number)
    filters.favLeagues = favLeagues
    filters.leagues = rootState.filters.queryFilters?.leagues || []

    // search by one id and do not add other filters
    if (payload.query.fixture_id) {
      filters = {}
      filters.fixtures = [parseInt(payload.query.fixture_id)]
    }

    // add user's keys for boxes
    filters.feed_keys = ['ppg', 'form', 'i']
    rootState.user_details.feed_keys.forEach(feed_key => {
      filters.feed_keys.push(feed_key)
    })
    // if date is older then today show only ft games
    if (date < moment().utcOffset(timezone).format('YYYY-MM-DD')) {
      filters.is_ft = {key: 'time.status', min: null, max: null, value: true}
    }
    // sort data
    const sort = localStorage.getItem('sort') ? JSON.stringify(JSON.parse(localStorage.getItem('sort')).value) : null
    const fixtures = {
      data: [],
      date,
      page
    }
    // keep params in state
    commit('SET_FIXTURES_PARAMS', { date, live, timezone, last_games, page: 1, sort, filters, limit, filter_type })
    const fixturesRes = await api.getFixturesV2(date, live, timezone, last_games, page, sort, state.source.token, filters, limit, filter_type)
    if (fixturesRes.data) {
      // if we don't have data for fav. leagues, extract other leagues
      if (!fixturesRes.data.data.length && page === 1 && state.fixtures.favLeagues) {
        commit('SET_FAV_LEAGUES', false)
        return await dispatch('GET_FIXTURES_V2', payload)
      }
      fixtures.data = fixturesRes.data.data
      commit('SET_META', fixturesRes.data.meta)
      // if we don't search for fav leagues, update fixtures only if we have data
      if (state.fixtures.favLeagues || fixtures.data.length) {
        commit('SET_FIXTURES', fixtures)
      }
      
      // used for mobile if a game is selected, show that game
      if (payload.query.fixture) {
        await dispatch('GET_SIDEBAR_FIXTURE_V2', {id: parseInt(payload.query.fixture), date, timezone})
      }
      // used for all pages, get first fixture from list in sidebar
      if(!state.sidebar_fixture && fixtures.data.length && state.fixtures.favLeagues && !payload.query.isAppGamePage && !payload.query.fixture) {
        await dispatch('GET_SIDEBAR_FIXTURE_V2', { id: fixtures.data[0].id, date, timezone })
      }

      // start websocket
      dispatch('SUBSCRIBE_FIXTURES_V2')

      commit('SET_LOADER', false)
    }
  },

  async GET_SIDEBAR_FIXTURE_V2({commit, state, dispatch}, payload) {
    if (payload.fixture) {
      commit('SET_SIDEBAR_FIXTURE', payload.fixture)
    }
    if (state.source_sidebar) {
      state.source_sidebar.cancel()
    }

    commit('SET_SOURCE_SIDEBAR', axios.CancelToken.source())
    commit('SET_LOADER', true)
    commit('SET_LOADER_SIDEBAR', true)

    const { date, live, timezone, last_games, filter_type } = state.fixturesParams
    // add all keys from sidebar
    const filters = {
      fixtures: [payload.id],
      feed_keys: ['form', 'goals_0_5', 'goals_5_10', 'goals_10_15', 'goals_15_20', 'goals_20_25', 'goals_25_30', 'goals_30_35', 'goals_35_40', 'goals_40_ht', 'goals_ht_50', 'goals_50_55', 'goals_55_60', 'goals_60_65', 'goals_65_70', 'goals_70_75', 'goals_75_80', 'goals_80_85', 'goals_85_ft', 'corners_0_5', 'corners_5_10', 'corners_10_15', 'corners_15_20', 'corners_20_25', 'corners_25_30', 'corners_30_35', 'corners_35_40', 'corners_40_ht', 'corners_ht_50', 'corners_50_55', 'corners_55_60', 'corners_60_65', 'corners_65_70', 'corners_70_75', 'corners_75_80', 'corners_80_85', 'corners_85_ft']
    }
    for (const key of Object.keys(sidebarStats)) {
      filters.feed_keys.push(...sidebarStats[key].map((stat) => stat.key))
    }

    const fixtureRes = await api.getFixturesV2(
      date,
      live,
      timezone,
      payload.lastGames || last_games,
      1,
      null,
      state.source_sidebar.token,
      filters,
      1,
      payload.filterType || filter_type
    )

    if (fixtureRes?.data?.data?.length) {
      const fixture = fixtureRes.data.data[0]
      if (fixture?.id) {
        if (payload.lastGames && !payload.isApp) {
          // used for sidebar when switch between last games
          // payload.isApp is used on first call on a new fixture page in app
          fixture.fromLastGames = true
        }
        delete fixture.odds // clear odds, it won't be necessary with a dedicated endpoint for sidebar
        commit('SET_SIDEBAR_FIXTURE', fixture)
        dispatch('SUBSCRIBE_FIXTURES_V2')
      }
    } else {
      commit('SET_SIDEBAR_FIXTURE', null)
      dispatch('SUBSCRIBE_FIXTURES_V2')
    }

    commit('SET_LOADER', false)
    commit('SET_LOADER_SIDEBAR', false)
  },

  PRESSURE_INDEX_TOGGLE_V2 ({ commit, dispatch }, payload) {
    commit('SET_PRESSURE_INDEX_TOGGLE', payload)
    // dispatch('SUBSCRIBE_FIXTURES') // todo pt v2: un job separat care ia doar meciuri actualizate in ultimele 10 secunde si trimite doar scor, cote, status catre toti userii subscribed
  },

  SUBSCRIBE_FIXTURES_V2 ({ state, commit }) {
    const channelNamePrefix = 'live_v2'
    const subscribedChannels = this.app.$pusher.allChannels()
    
    // Unsubscribe from all existing channels
    subscribedChannels.forEach(channel => {
      if (channel.name.startsWith(channelNamePrefix)) {
        this.app.$pusher.unsubscribe(channel.name)
      }
    })
    
    // Subscribe to appropriate channels for all fixtures
    for (const fixture of state.fixtures.data) {
      const isSidebarFixture = state.sidebar_fixture?.id === fixture.id
      const channelName = `${channelNamePrefix}_${fixture.id}${isSidebarFixture ? '' : '_lite'}`
      
      const channel = this.app.$pusher.subscribe(channelName)
      channel.bind('live', data => {
        commit('UPDATE_FIXTURES_V2', data)
      })
    }
  }
}

