import Pusher from 'pusher-js'
// https://github.com/pusher/pusher-js

export default ( context, inject ) => {
  // Pusher.log = (msg) => {
  //   console.log(msg)
  // }

  const pusher = new Pusher(process.env.SOKETI_APP_KEY, {
      wsHost: process.env.SOKETI_HOST,
      forceTLS: true,
      encrypted: false,
      disableStats: true,
      enabledTransports: ['ws', 'wss']
  })
  
  inject('pusher', pusher)
}
