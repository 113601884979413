import Vue from 'vue'
export default ({ app }) => {
  let syncElements = []
  let scrollTimeout = null

  const handleScroll = (event) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.target
    const scrollTo =
      scrollLeft + clientWidth === scrollWidth ? scrollWidth : scrollLeft

    // Clear any existing scrollTimeout
    clearTimeout(scrollTimeout)

    // Set a new scrollTimeout to call a function after a delay
    scrollTimeout = setTimeout(() => {
      syncElements.forEach((element) => {
        if (element.id !== event.target.id) {
          element.scrollTo({
            left: scrollTo,
            behavior: 'smooth' // Add smooth scrolling behavior
          })
        }
      })
      app.store.commit(
        'scrollPosition/SET_MATCH_SCROLL_POSITION', // updated the position of the scroll for the icon
        scrollTo
      )
    }, 100) // Adjust the delay as needed
  }

  const addScrollListeners = () => {
    syncElements = document.querySelectorAll('.scroll-sync')
    syncElements.forEach((element) => {
      element.addEventListener('scroll', handleScroll)
    })
  }

  const removeScrollListeners = () => {
    syncElements.forEach((element) => {
      element.removeEventListener('scroll', handleScroll)
    })
    syncElements = []
  }
  // used three different lifecycle for when mounted for first render then updated  when tabs are changed and remove the listeners when destroyed
  Vue.mixin({
    mounted() {
      addScrollListeners()
    },
    updated() {
      removeScrollListeners()
      addScrollListeners()
    },
    beforeDestroy() {
      removeScrollListeners()
    }
  })
}
